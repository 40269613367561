<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<v-layout v-if="isArchived" row wrap>
				<v-flex xs12>
					<CompanyRestorer :company="company" />
				</v-flex>
				<v-flex xs12>
					<VendorDeletor :company="company" />
				</v-flex>
			</v-layout>
			<v-layout v-else row wrap>
				<v-flex v-if="hasCustomerTransfererModule" xs12>
					<CompanyTransferer :company="company" />
				</v-flex>
				<v-flex xs12>
					<CompanyDeactivator :company="company" />
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomerDetailsAdministration',
	components: {
		VendorDeletor: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/VendorDeletor')
		}),
		CompanyDeactivator: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/CompanyDeactivator')
		}),
		CompanyRestorer: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/CompanyRestorer')
		}),
		CompanyTransferer: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/CompanyTransferer')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			hasCustomerTransfererModule: false
		}
	},
	computed: {
		isArchived: function () {
			return this.company.deleted_at
		}
	},
	watch: {
		'company.id': {
			handler: function (val) {
				if (val) {
					this.hasCustomerTransfererModule = this.appService.hasModule('customer-transferer')
				}
			},
			immediate: true
		}
	}
}
</script>
